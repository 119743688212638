import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { ThemeProvider } from '../context/theme'
import mapSectionsToComponent from '../utils/mapSectionsToComponent'
import SectionComponents from '../components/SectionComponents'
import SearchHeader from '../components/SearchHeader'
import SubscribeForm from '../components/SubscribeForm'
import { SiteConfig } from '../types/ContentBuilder'

const themeDefault = {
    color: {
        primary: '#001689',
        secondary: '#1dbbb2',
        third: 'grey',
    },
}

const searchHeaderBackgroundImage = require('../images/search_background_image.png')

interface HelpAdviceProps {
    pageContext: {
        content: []
        config: SiteConfig
    }
}

export default ({ pageContext }: HelpAdviceProps) => {
    const sections = mapSectionsToComponent(pageContext.content)
    return (
        <ThemeProvider value={themeDefault}>
            <Layout>
                <SEO title="Help And Advice" />
                <SearchHeader
                    headerText={`"I wish I'd know then, what I know now"`}
                    subHeaderText={'Well now you can!'}
                    backgroundImage={searchHeaderBackgroundImage}
                    options={{ theme: themeDefault }}
                />
                <SectionComponents sections={sections} />
                <SubscribeForm
                    theme={themeDefault}
                    config={pageContext.config}
                />
            </Layout>
        </ThemeProvider>
    )
}
